body {
  margin: 0;
  font-size: 12px;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

iframe[style*="2147483647"] {
  display: none;
}

h1, h2, h3, h4, h5, h6, p {
  font-weight: normal;
  margin: 0;
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

input, textarea {
  font-family: inherit;
}

table {
  border: none;
  border-collapse: collapse;
}

tr, td {
  border: none;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ms-Fabric--isFocusHidden {
  overflow-y: scroll !important;
}

.ms-Callout {
  background: #ffffff;
  border: solid 1px #323130;
  border-radius: 2px;
  overflow: hidden;
}

.ms-Callout button {
  background: transparent;
}

.ms-Button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.ms-Dialog .ms-Dialog-actions .ms-Button {
  font-size: 12px !important;
}

.ms-Modal .dialog-content-pre .ms-Dialog-inner .ms-Dialog-content {
  white-space: pre-line;
}
